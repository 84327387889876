import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

export type SEOType = {
  description?: string
  meta?: {
    name: string
    content: string
  }[]
  title?: string
  webflow?: string
}

const SEO: React.FC<SEOType> = ({ description, meta = [], title, webflow }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang: 'fr',
        'data-wf-page': webflow,
      }}
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
      link={[
        {
          rel: 'icon',
          href: withPrefix('favicon.png'),
        },
      ]}
    ></Helmet>
  )
}

export default SEO
