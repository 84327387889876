
  import React from "react"
  import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

  import remove from "../images/remove.png"

  const LayoutView = (props) => {
    const { t } = useTranslation()

    return (<div>
  <div data-w-id="99ce8cce-b71a-4a86-73b7-1cfadb0ecd0c" className="announcement-bar" style={{display: props.isDisplayAnnouncementBar ? "" : "none"}}>
    <div className="container flex reverse">
      <div className="left-flex">
        <div className="badge hide"><Trans>Profitez-en !</Trans></div>
        <div className="announcement-text"><Trans>Pour fêter la nouvelle année, nous vous offrons 6&nbsp;mois d'accompagnement !</Trans><strong><br /></strong></div>
      </div>
      <div data-w-id="99ce8cce-b71a-4a86-73b7-1cfadb0ecd15" className="x-button" onClick={props.hideAnnouncementBar}><img src={remove} loading="lazy" width={12} alt="remove" /></div>
    </div>
  </div>
  <div data-collapse="medium" data-animation="default" data-duration={400} data-w-id="9dc81611-8047-6c5d-2e0d-c24df000d9e2" role="banner" className="navbar with-social w-nav">
    <div className="container grid w-container"><Trans>{props.menu}</Trans></div>
  </div>
  <div className="wf-section">{props.children}</div>
  <div className="footer">
    <div className="container">
      <div className="footer-flex-wrap">
        <div className="footer-block-left">
          <a href="#" className="footer-link w-inline-block">
            <div className="logo-text white"><Trans>INTERSTELLAR</Trans></div>
          </a>
          <div className="top-margin-30px">
            <div className="footer-cta"><Trans>Nous créons gratuitement des sites pour vignerons 🍷</Trans></div>
          </div>
        </div>
        <div className="line-break" />
        <div className="footer-block-right">
          <div className="w-layout-grid grid">
            <div className="footer-column">
              <div className="footer-title"><Trans>Navigation</Trans></div>
              <Link to="/concept" className="footer-link-dark"><Trans>Notre agence</Trans></Link>
              <Link to="/project" className="footer-link-dark"><Trans>Votre projet</Trans></Link>
              <Link to="/pricing" className="footer-link-dark"><Trans>Tarifs</Trans></Link>
              <Link to="/contact" className="footer-link-dark"><Trans>Contact</Trans></Link>
            </div>
            <div className="footer-column">
              <div className="footer-title"><Trans>Information</Trans></div>
              <Link to="/legal" className="footer-link-dark"><Trans>Mentions légales</Trans></Link>
            </div>
            <div className="footer-column">
              <div className="footer-title"><Trans>Nous contacter</Trans></div>
              <div>
                <p className="footer-paragraph">contact@interstellar.run</p>
                <p className="footer-paragraph"><Trans>06&nbsp;69&nbsp;31&nbsp;97&nbsp;96</Trans></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>)
  };
  
  export default LayoutView
  