import React, { MutableRefObject, useEffect, useRef, useState } from 'react'

import Seo, { SEOType } from './seo'
import LayoutView from '../views/layout.view.js'
import '../css/typography.css'
import '../css/webflow.css'
import Hamburger from 'hamburger-react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'

type DataProps = {
  children: React.ReactNode
  seo?: SEOType
}

function useOutsideAlerter(ref: MutableRefObject<HTMLDivElement | null>, cb: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ref])
}

const Layout: React.FC<DataProps> = ({ children, seo }) => {
  const [displayAnnouncementBar, setDisplayAnnouncementBar] = useState(false)
  const [displayMenuBurger, setDisplayMenuBurger] = useState(false)
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation('custom')

  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef, () => setDisplayMenuBurger(false))

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isDisplay =
        window.sessionStorage.getItem('displayAnnouncementBar') === 'true' ||
        !window.sessionStorage.getItem('displayAnnouncementBar')

      setDisplayAnnouncementBar(isDisplay)
    }
  })

  const getMenuBar = (isMobile: boolean) => {
    const displayInd = language === 'fr' ? '0' : '(+33) '
    return (
      <nav
        role="navigation"
        id="w-node-c24df000d9e7-f000d9e2"
        className="nav-menu w-nav-menu"
        {...(isMobile && { 'data-nav-menu-open': true })}
      >
        <Link to="/concept" className="nav-link w-inline-block">
          <div className="nav-text">{t('Notre Concept')}</div>
        </Link>
        <Link to="/project" className="nav-link w-inline-block">
          <div className="nav-text">{t('Nos Réalisations')}</div>
        </Link>
        <Link to="/pricing" className="nav-link w-inline-block">
          <div className="nav-text">{t('Tarifs')}</div>
        </Link>
        <Link to="/contact" className="nav-link w-inline-block">
          <div className="nav-text">{t('Contact')}</div>
        </Link>
        <Link to="/contact" className="nav-link w-inline-block">
          <div className="nav-text free-study">
            {displayInd}6&nbsp;69&nbsp;31&nbsp;97&nbsp;96
            <br />
            <span className="free-study">{t('Votre étude GRATUITE !')} </span>
            <br />
          </div>
        </Link>
      </nav>
    )
  }

  const getMenu = (
    <React.Fragment>
      <a href="/" id="w-node-c24df000d9e4-f000d9e2" className="logo w-nav-brand">
        <div className="logo-text">INTERSTELLAR</div>
      </a>
      {getMenuBar(false)}
      <div className="w-nav-button">
        <Hamburger label="Menu" toggled={displayMenuBurger} toggle={setDisplayMenuBurger} />
      </div>
      <div
        className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0"
        style={displayMenuBurger ? { display: 'block', height: '4923.2px' } : { display: 'none' }}
      >
        {getMenuBar(true)}
      </div>
    </React.Fragment>
  )

  const changeLanguageFooter = (
    <ul className="languages">
      {languages
        .filter((lng) => lng !== language)
        .map((lng) => {
          const choiceLng = lng === 'en' ? 'English' : 'Français'
          return (
            <li key={lng}>
              <Link className="footer-link-dark" to={originalPath} language={lng}>
                {choiceLng}
              </Link>
            </li>
          )
        })}
    </ul>
  )

  return (
    <>
      <Seo {...seo} />
      <LayoutView
        isDisplayAnnouncementBar={displayAnnouncementBar}
        hideAnnouncementBar={() => {
          setDisplayAnnouncementBar(false)
          window.sessionStorage.setItem('displayAnnouncementBar', 'false')
        }}
        isDisplayMenuBurger={displayMenuBurger}
        setDisplayMenuBurger={setDisplayMenuBurger}
        menu={getMenu}
        changeLanguage={changeLanguageFooter}
      >
        <main>{children}</main>
      </LayoutView>
    </>
  )
}

export default Layout
